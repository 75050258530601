export type MediaQueryBreakpointsKeys = keyof typeof MediaQueryBreakpoints;

export enum MediaQueryBreakpoints {
  sm = '640px',
  md = '768px',
  lg = '1024px',
  xl = '1280px',
  xxl = '1536px',
  x3l = '1920px',
}
