import {
  Injectable,
  OnDestroy,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { MediaQueryBreakpoints } from './media-query.enum';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class MediaQueryService implements OnDestroy {
  isMobile = signal(false);
  isDesktop = signal(false);

  sm = signal(false);
  md = signal(false);
  lg = signal(false);
  xl = signal(false);
  xxl = signal(false);

  breakpoints: { [key: string]: WritableSignal<boolean> } = {
    [`(max-width: ${MediaQueryBreakpoints.sm})`]: this.isMobile,
    [`(max-width: ${MediaQueryBreakpoints.lg})`]: this.isDesktop,
    [`(min-width: ${MediaQueryBreakpoints.sm})`]: this.sm,
    [`(min-width: ${MediaQueryBreakpoints.md})`]: this.md,
    [`(min-width: ${MediaQueryBreakpoints.lg})`]: this.lg,
    [`(min-width: ${MediaQueryBreakpoints.xl})`]: this.xl,
    [`(min-width: ${MediaQueryBreakpoints.xxl})`]: this.xxl,
  };

  layoutChanges = this.breakpointObserver.observe(
    Object.keys(this.breakpoints),
  );

  layoutChangesSub;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.layoutChangesSub = this.layoutChanges.subscribe((state) => {
      Object.keys(this.breakpoints).forEach((key) => {
        this.breakpoints[key].set(state.breakpoints[key]);
      });
    });
  }

  ngOnDestroy(): void {
    this.layoutChangesSub.unsubscribe();
  }
}
